import mochi from "./img/mochi_logo_trimAsset 2.svg";
import topleft from "./img/topleft.png";
import btmright from "./img/btmright.png";
import discord from "./img/discord.png";
import twitter from "./img/twitter.png";

function App() {
	return (
		<div className="app">
			<img className="topleft" src={topleft} />
			<img className="btmright" src={btmright} />
			<img src={mochi} className="mochi" alt="mochi" />
			<div className="socials">
				<a href="https://discord.gg/mochimunchkins" target="_blank"><img src={discord} /></a>
				<a href="https://x.com/mochi_munchkins" target="_blank"><img src={twitter} /></a>
			</div>
		</div>
	);
}

export default App;
